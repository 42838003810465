import { useCallback, useEffect, useMemo, useState, useSyncExternalStore } from 'react';
import {
  AssetsClipSuggestClipsCreateOkResponse,
  ClipAssetResponse,
  assetsClipSuggestClipsCreate,
  assetsSavedSearchGenerateAssetsCreate
} from '@goldcast/api/content';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import ClipsList from './ClipsList';
import { GENERATE_DEFAULT_LIMIT } from '../TranscriptPage/constants';
import ClipPlayer from '../Clip/ClipPlayer/ClipPlayer';
import ContentDialog from '../Sessions/uiComponents/ContentDialog/ContentDialog';
import RenameTitleModal from '../Sessions/AssetsTable/RenameTitleModal';
import { updateAssetTitle } from '../Sessions/AssetsTable/AssetsTableUtils';
import SessionTitlePopup from '../SessionDetail/SessionTitlePopup';
import ClipPlayerWrapper from '../Clip/ClipPlayer/ClipPlayerWrapper';
import { handleClipLockedError } from '../Clip/utils';
import NewTemplateButton from '../Clip/SideBar/TemplatesSideBar/NewTemplateButton';
import ClipEditTranscript from './ClipEditTranscript';
import { TIMELINE_DISPLAY_TYPE } from '../Clip/ClipPlayer/ClipTimelineV2/ClipTimelineTypes';
import SavedSearchTitlePopup from '../SavedSearch/SavedSearchTitlePopup';
import {
  allClips,
  appendAllClips,
  canGenerateClips,
  prependAllClips,
  removeClip,
  setCanGenerateClips,
  setClipGeneratingCount,
  updateClipInList
} from '@/stores/clip';
import useFreeTrialHook from '@/hooks/useFreeTrialHook';
import { currentUser } from '@/stores/user';
import { core } from '@/stores/core';
import IconButton from '@/components/atoms/Button/IconButton';
import { Clip } from '@/domains/asset';
import { showErrorToast } from '@/libs/toast/toast';
import { addUnseenClipIds } from '@/stores/unseenClips';
import FreeTrialUpgradeNag from '@/components/molecules/FreeTrial/FreeTrialUpgradeNag';
import ClipSharePopup from '@/components/molecules/SharePopup/ClipSharePopup/ClipSharePopup';
import useAnalytics from '@/hooks/useAnalytics';
import { classnames } from '@/libs/utils';
import { userPreferencesStore, toggleListCollapse } from '@/stores/userPreferencesStore';
import DownloadsPopup from '@/components/molecules/DownloadsPopup/DownloadsPopup';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';
import ClipUndoRedo from '@/components/molecules/ClipUndoRedo';
import useAssetRating from '@/hooks/useAssetRating';
import useSavedSearch from '@/hooks/useSavedSearch';
import { savedAISearch } from '@/stores/savedAISearch';
import ContentSettings from '@/components/molecules/ContentSettings';

export default function ClipsListPage() {
  const { eventId: routeEventId, broadcastId: routeBroadcastId } = useParams<{
    eventId: string;
    broadcastId: string;
  }>();

  const allClipsStore = useSyncExternalStore(allClips.subscribe, allClips.getSnapshot);
  const canGenerateClipsState = useSyncExternalStore(canGenerateClips.subscribe, canGenerateClips.getSnapshot);
  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);

  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);
  const isEasyClipCustomizerEnabled = featureFlags[FeatureFlagKeys.Use_CL_Easy_Clip_Customizer];
  const showNewTimeline = featureFlags[FeatureFlagKeys.Use_CL_New_Timeline];

  const [activeClip, setActiveClip] = useState<Clip | null>(null);
  const [renamingClip, setRenamingClip] = useState<Clip | null>(null);
  const { isListCollapsed } = useSyncExternalStore(userPreferencesStore.subscribe, userPreferencesStore.getSnapshot);

  const { incrementClipsUsed } = useFreeTrialHook();
  const { isSavedSearchPage } = useSavedSearch();

  const { eventId, broadcastId } = useMemo(() => {
    if (!activeClip) {
      return {
        eventId: routeEventId,
        broadcastId: routeBroadcastId
      };
    }
    return {
      eventId: activeClip.content.project.id,
      broadcastId: activeClip.content.id
    };
  }, [activeClip, routeEventId, routeBroadcastId]);

  const { trackContentCreated, trackContentLabUsage } = useAnalytics();
  const { resetAllAssetRatings } = useAssetRating();

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const { mutate: updateClipTitle } = useMutation(updateAssetTitle, {
    onError: () => showErrorToast("Oops! Couldn't rename the asset. Please try again.")
  });

  useEffect(() => {
    return () => {
      resetAllAssetRatings();
    };
  }, []);

  useEffect(() => {
    if (!allClipsStore.length) {
      return;
    }

    const clipId = searchParams.get('activeClipId');
    const activeClip = allClipsStore.find(clip => clip.id === clipId) ?? allClipsStore[0];
    setActiveClip(activeClip ?? null);
    setSearchParams({ activeClipId: activeClip?.id }, { replace: true, state: location.state });
  }, [searchParams, allClipsStore]);

  function trackClipsGeneratedByAI(clips: AssetsClipSuggestClipsCreateOkResponse) {
    trackContentCreated({
      count: clips.length,
      source: 'Autogenerate',
      state: 'Created'
    });
    clips.forEach(clip => {
      trackContentCreated({
        asset: clip,
        source: 'Autogenerate',
        state: 'Created'
      });
    });
  }

  function generateClips(): Promise<void> {
    setClipGeneratingCount(GENERATE_DEFAULT_LIMIT);
    return triggerGenerateClipsCall()
      .then(clips => {
        setClipGeneratingCount(0);

        if (!clips.length) {
          setCanGenerateClips(false);
          return;
        }

        if (isSavedSearchPage) {
          appendAllClips(clips);
          setTimeout(() => {
            document.getElementById(clips[clips.length - 1].id)?.scrollIntoView({ inline: 'end', behavior: 'smooth' });
          }, 500);
        } else {
          prependAllClips(clips);
        }
        addUnseenClipIds(clips.map((clip: ClipAssetResponse) => clip.id));
        searchParams.set('activeClipId', clips[0].id);
        trackClipsGeneratedByAI(clips);

        if (!coreStore.content?.is_sample_upload) {
          incrementClipsUsed(clips.length);
        }
      })
      .catch(e => {
        setClipGeneratingCount(0);
        setCanGenerateClips(true);
        showErrorToast('Generation of clips stalled. Ensure your selections are clear and try again.');
        trackContentCreated({
          source: 'Autogenerate',
          state: 'Failed',
          failureReason: e?.mesage
        });
      });
  }

  function triggerGenerateClipsCall() {
    if (isSavedSearchPage) {
      return assetsSavedSearchGenerateAssetsCreate({
        id: savedAISearch.getSnapshot()!.id,
        queryParams: {
          limit: 5
        }
      });
    }
    return assetsClipSuggestClipsCreate({
      body: {
        content_id: broadcastId as string,
        project_id: eventId as string,
        organization_id: currentUser.getSnapshot()?.organization as string,
        limit: GENERATE_DEFAULT_LIMIT
      },
      queryParams: {}
    });
  }

  const onEditClip = (clip: Clip) => {
    if (clip.locked) {
      handleClipLockedError(clip);
      return;
    }

    trackContentLabUsage({
      asset: clip
    });
    navigate(`/${eventId}/${broadcastId}/clips/${clip.id}`);
  };

  const handleTranscriptError = (err: any) => {
    navigate(`/error/${err?.statusCode}`, { replace: true });
  };

  const handleOnDelete = useCallback(
    (clipId: string) => {
      const clipIndex = allClipsStore.findIndex(clip => clip.id === clipId);
      removeClip(clipId).then(() => {
        if (allClipsStore[clipIndex - 1]) {
          setSearchParams({ activeClipId: allClipsStore[clipIndex - 1].id }, { replace: true });
        } else if (allClipsStore[clipIndex + 1]) {
          setSearchParams({ activeClipId: allClipsStore[clipIndex + 1].id }, { replace: true });
        } else {
          setSearchParams(undefined, { replace: true });
        }
      });
    },
    [allClipsStore, setSearchParams]
  );

  const closeRenamingClipDialog = () => {
    setRenamingClip(null);
  };

  const onSaveNewTitle = (newTitle: string) => {
    if (!renamingClip) return;

    if (renamingClip.title !== newTitle) {
      // @kashish TODO - any is used here because Clip and AssetList types are clashing. We should use AssetList instead of Clip everywhere
      updateClipTitle({ ...renamingClip, title: newTitle } as any);
      updateClipInList({ ...renamingClip, title: newTitle });
    }

    closeRenamingClipDialog();
  };

  const onGenerateSocialPost = (clipId: string) => {
    navigate(`/${eventId}/${broadcastId}/social`, { state: { clipId } });
  };

  const [isClipsListHidden, setIsClipsListHidden] = useState(false);

  return (
    <div className="flex h-full overflow-hidden">
      <div
        className={classnames(
          'absolute flex h-full w-[32rem] overflow-visible py-2 transition-all duration-[400ms] ease-in-out',
          {
            '!w-[16.5rem]': isListCollapsed
          }
        )}
      >
        <ClipsList
          clips={allClipsStore}
          canGenerate={canGenerateClipsState}
          isCollapsed={isListCollapsed}
          isHidden={isClipsListHidden}
          generateClips={generateClips}
          onDelete={handleOnDelete}
          onEditClip={onEditClip}
          onRenameClip={setRenamingClip}
          onGenerateSocialPost={onGenerateSocialPost}
          toggleCollapse={toggleListCollapse}
        />
        {activeClip && coreStore.content && (
          <ClipEditTranscript
            clip={activeClip}
            broadcastId={broadcastId}
            eventId={eventId}
            isClipsListCollapsed={isListCollapsed}
            toggleClipsListCollapse={toggleListCollapse}
            setIsClipsListHidden={setIsClipsListHidden}
          />
        )}
      </div>
      <div
        className={classnames(
          'flex grow flex-col overflow-hidden pl-[32rem] transition-all duration-[400ms] ease-in-out',
          {
            '!pl-[16.5rem]': isListCollapsed
          }
        )}
      >
        <div className="relative ml-1 flex h-full grow flex-col">
          {activeClip?.id && (
            <ClipPlayerWrapper clipId={activeClip.id} handleTranscriptError={handleTranscriptError}>
              <div className="relative flex w-full items-center justify-between p-4">
                {isSavedSearchPage ? <SavedSearchTitlePopup /> : <SessionTitlePopup />}
                <div className="flex items-center space-x-3">
                  <FreeTrialUpgradeNag />
                  {isEasyClipCustomizerEnabled && <ClipUndoRedo isInline={true} />}
                  {broadcastId && <DownloadsPopup contentId={broadcastId} />}
                  {!isEasyClipCustomizerEnabled && (
                    <IconButton
                      icon="IconScissors"
                      trackingId="edit-clip-button"
                      title="Edit Clip"
                      content="Edit Clip"
                      variation="outline"
                      buttonClassName="bg-white"
                      onClick={() => onEditClip(activeClip)}
                    />
                  )}
                  {isEasyClipCustomizerEnabled && <NewTemplateButton className="bg-white" />}
                  <ClipSharePopup activeClipId={activeClip?.id} downloadIntent={location.state?.downloadIntent} />
                  {isSavedSearchPage && <ContentSettings />}
                </div>
              </div>
              <div className="h-full">
                <ClipPlayer
                  playerClasses="lg:p-16"
                  inlineEditEnabled={isEasyClipCustomizerEnabled}
                  showNewTimeline={showNewTimeline && !activeClip.locked}
                  timelineDisplayType={
                    isEasyClipCustomizerEnabled && !activeClip.locked
                      ? TIMELINE_DISPLAY_TYPE.FILMSTRIP
                      : TIMELINE_DISPLAY_TYPE.PROGRESS_BAR
                  }
                />
              </div>
            </ClipPlayerWrapper>
          )}
          {!allClipsStore.length && isSavedSearchPage && (
            <div className="flex h-full items-center justify-center">
              <p className="w-80 text-center text-sm text-slate-600">
                There are no clips for this saved search.{' '}
                <span className="cursor-pointer underline" onClick={generateClips}>
                  Generate clips
                </span>{' '}
                or try search with a different term.
              </p>
            </div>
          )}
        </div>
      </div>
      {renamingClip ? (
        <ContentDialog isOpen={!!renamingClip} setIsOpen={closeRenamingClipDialog} title="Rename" size="xsmall">
          <RenameTitleModal
            existingTitle={renamingClip.title}
            onSubmit={onSaveNewTitle}
            onClose={closeRenamingClipDialog}
          />
        </ContentDialog>
      ) : null}
    </div>
  );
}
