import React, { memo, useEffect, useMemo, useState } from 'react';
import { CAPTION_LOOP_DURATION, transitionTemplateMocks } from './constants';
import MockTemplateBackgroundTransitionItem from './MockTemplateBackgroundTransitionItem';
import { BackgroundTransitionTemplateMock } from './types';
import { classnames } from '@/libs/utils';
import styles from './MockTemplateBackgroundTransition.module.scss';

function MockTemplateBackgroundTransition() {
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(prev => {
        if (prev >= CAPTION_LOOP_DURATION) {
          return 0;
        } else {
          return prev + 0.1;
        }
      });
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const columns = useMemo(() => {
    const baseArray = [...transitionTemplateMocks, ...transitionTemplateMocks];
    const numberOfColumns = 5;
    const columnArray = Array.from({ length: numberOfColumns }, () => []) as BackgroundTransitionTemplateMock[][];

    baseArray.forEach((item, index) => {
      columnArray[index % numberOfColumns].push(item);
    });

    return columnArray;
  }, []);

  return (
    <div className="absolute inset-0 grid grid-cols-5 gap-8 px-8 blur-[6px]">
      {columns.map((column, index) => (
        <Column key={index} column={column} currentTime={currentTime} scrollClass={styles[`scroll${index}`]} />
      ))}
    </div>
  );
}

const Column = ({ column, currentTime, scrollClass }) => {
  return (
    <div className="h-full w-full overflow-hidden">
      <div className="flex flex-col">
        <div className={classnames(scrollClass, 'flex flex-col')}>
          {column.map(item => (
            <div
              key={item.id}
              className={classnames(
                item.timeline.aspectRatioClass,
                'mb-10 flex items-center justify-center rounded-md bg-slate-100'
              )}
            >
              <MockTemplateBackgroundTransitionItem templateMock={item} currentTime={currentTime} />
            </div>
          ))}
        </div>
        <div className={classnames(scrollClass, 'flex flex-col')}>
          {column.map(item => (
            <div
              key={item.id + '_repeat'}
              className={classnames(
                item.timeline.aspectRatioClass,
                'mb-10 items-center justify-center rounded-md bg-slate-100'
              )}
            >
              <MockTemplateBackgroundTransitionItem templateMock={item} currentTime={currentTime} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(MockTemplateBackgroundTransition);
