import React, { MouseEvent, useCallback, useMemo, useRef, useSyncExternalStore } from 'react';
import moment from 'moment';
import { TIME_SECTIONS_COUNT } from './constants';
import { CustomEvents } from '@/libs/eventBus/constants';
import EventBus from '@/libs/eventBus/eventBus';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import { player } from '@/stores/player';
import { classnames } from '@/libs/utils';
import { Clip } from '@/domains/asset';
import { formatDuration } from '@/libs/core';

export default function Timeline({ clips, visible }: { clips: Clip[]; visible: boolean }) {
  const transcriptStore = useTranscriptContext();
  const playerStore = useSyncExternalStore(player.subscribe, player.getSnapshot);

  const timeline = useRef<HTMLDivElement>(null);
  const timeHoverAxis = useRef<HTMLDivElement>(null);
  const timeSectionHoverAxis = useRef<HTMLDivElement>(null);

  const end = useMemo(() => transcriptStore?.end as number, [transcriptStore?.end]);
  const timeSections = useMemo(() => {
    // Handle 0 or NaN for Safari
    if (!playerStore.duration) return [];
    const steps = Math.floor(playerStore.duration / 12);
    return Array.from({ length: TIME_SECTIONS_COUNT }, (_, i) => moment.utc(i * steps * 1000).format('HH:mm:ss'));
  }, [playerStore?.duration]);

  const setTimeFromTimeline = useCallback(
    (e: MouseEvent) => {
      if (!timeline.current) return;
      const x = e.clientX;
      const { left } = timeline.current.getBoundingClientRect();
      const pos = (x - left) / timeline.current.offsetWidth;
      const time = end * pos;
      const clip = clips.find(c => time >= c.asset_metadata.start && time <= c.asset_metadata.end);
      EventBus.dispatch(CustomEvents.TimelineChanged, {
        time,
        startPlaying: !playerStore.paused || !!clip
      });
    },
    [playerStore, timeline, end]
  );

  const onMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!timeline.current || !timeHoverAxis.current || !timeSectionHoverAxis.current) return 0;

      const x = e.clientX;
      const { left, width } = timeline.current.getBoundingClientRect();
      timeHoverAxis.current.style.left = `${x - left}px`;
      timeSectionHoverAxis.current.style.left = `${x - left}px`;
      timeSectionHoverAxis.current.innerText = formatDuration(((x - left) / width) * (transcriptStore?.end ?? 0));
    },
    [transcriptStore?.end]
  );

  const speakers = useMemo(
    () => Object.values(transcriptStore?.speakersWithDetails || []).filter(speaker => !speaker.parent_id),
    [transcriptStore?.speakersWithDetails]
  );

  const handleEditSpeaker = useCallback(speakerId => {
    EventBus.dispatch(CustomEvents.OpenContentSettings, { speakerId });
  }, []);

  return (
    <div
      onClick={setTimeFromTimeline}
      onMouseMove={onMouseMove}
      className={classnames(
        'group relative grid w-full grid-cols-[1fr] grid-rows-[max-content,1fr] transition-all duration-500',
        {
          'max-h-0 overflow-hidden': !visible,
          'mt-3 max-h-60 border': visible
        }
      )}
      id="timeline"
      data-testid="timeline"
      ref={timeline}
    >
      <div
        className="pointer-events-none absolute z-20 h-full w-[1px] bg-deep-orange-600"
        style={{ left: (playerStore.currentTime / end) * 100 + '%' }}
      />
      <div
        className="pointer-events-none invisible absolute z-20 h-full w-[1px] bg-slate-400 group-hover:visible"
        ref={timeHoverAxis}
      />
      <div className="relative flex w-full justify-between overflow-hidden border-b-slate-700 py-0.5 text-[10px] text-slate-400">
        <div
          className="absolute left-0 top-0 z-20 h-full w-0 bg-slate-400 opacity-20 transition-all duration-300"
          style={{ width: (playerStore.currentTime / end) * 100 + '%' }}
        />
        {timeSections.map(timeSection => (
          <div key={timeSection}>{timeSection}</div>
        ))}
        <div
          className="invisible absolute top-[1px] z-20 translate-x-[-50%] rounded-md bg-white px-2 py-[1px] font-bold text-black group-hover:visible"
          ref={timeSectionHoverAxis}
          data-testid="timeline-hover"
        />
      </div>
      <div className="relative w-full overflow-auto border-t border-t-slate-100 pb-4">
        {clips.map(clip => {
          return (
            <button
              className={classnames('absolute top-0 h-full border-dashed border-yellow-400 bg-yellow-100', {
                'border-[2px] bg-opacity-80':
                  clip.asset_metadata.start <= playerStore.currentTime &&
                  clip.asset_metadata.end >= playerStore.currentTime,
                'border-[1px] bg-opacity-40':
                  clip.asset_metadata.start > playerStore.currentTime ||
                  clip.asset_metadata.end < playerStore.currentTime
              })}
              style={{
                width: ((clip.asset_metadata.end - clip.asset_metadata.start) / end) * 100 + '%',
                left: (+clip.asset_metadata.start / end) * 100 + '%'
              }}
              key={clip.asset_metadata.id}
            />
          );
        })}
        {speakers.map((speaker, index) => (
          <div className="pointer-events-none mt-2 w-full" key={speaker.key + index}>
            <div className={`shrink-0 text-[10px] font-medium uppercase tracking-wider ${speaker.color} ml-0.5`}>
              <span className="pointer-events-auto cursor-pointer" onClick={() => handleEditSpeaker(speaker.id)}>
                {`${speaker.first_name} ${speaker.last_name}`}
              </span>
            </div>
            <div className="relative mt-0.5 h-1.5 w-full grow rounded-md bg-slate-200 bg-opacity-75">
              {Object.keys(speaker.speakingSlots).map(key => {
                return (
                  <span
                    className={`absolute ${speaker.bgColor} h-full rounded-full`}
                    style={{
                      width: ((speaker.speakingSlots[key] - +key) / end) * 100 + '%',
                      left: (+key / end) * 100 + '%'
                    }}
                    key={speaker.first_name + speaker.last_name + key}
                  />
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
