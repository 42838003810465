import { Transition } from '@headlessui/react';
import { IconSparkles } from '@tabler/icons-react';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState, useSyncExternalStore } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  assetsSavedSearchCreate,
  assetsSavedSearchList,
  PaginatedSavedSearchesList,
  SavedSearches
} from '@goldcast/api/content';
import { AI_SEARCH_ERROR_CODES, SEARCH_OPTIONS } from './constants';
import { AiClipPromptOption } from './types';
import ContentDialog from '../../uiComponents/ContentDialog/ContentDialog';
import NoResultsDialog from './NoResultsDialog';
import SavedSearchDropdownItem from './SavedSearchDropdownItem';
import SavedSearchLoader from './SavedSearchLoader';
import IconButton from '@/components/atoms/Button/IconButton';
import Icon from '@/components/atoms/Icon';
import { classnames } from '@/libs/utils';
import useDialog from '@/components/organisms/useDialog';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';
import { useAppContext } from '@/context/AppContext/AppContext';
import { showErrorToast } from '@/libs/toast/toast';
import useAnalytics from '@/hooks/useAnalytics';
import useDebounce from '@/hooks/useDebounce';
import { currentUser } from '@/stores/user';

function SavedSearchInput() {
  const [prompt, setPrompt] = useState('');
  const [selectedPromptOption, setSelectedPromptOption] = useState<AiClipPromptOption | null>(null);
  const [arePromptOptionsVisible, setArePromptOptionsVisible] = useState(false);
  const [isPromptOptionSelected, setIsPromptOptionSelected] = useState(false);
  const [savedSearches, setSavedSearches] = useState<SavedSearches[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const promptMenu = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    isOpen: isNoResultsDialogOpen,
    closeDialog: closeNoResultsDialog,
    openDialog: openNoResultsDialog
  } = useDialog();
  const { isOpen: isLoaderDialogOpen, closeDialog: closeLoaderDialog, openDialog: openLoaderDialog } = useDialog();

  const navigate = useNavigate();
  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);
  const { adminAppStore, logger } = useAppContext();
  const { trackAiSearch } = useAnalytics();

  useEffect(() => {
    loadSavedSearches();
  }, []);

  const loadSavedSearches = useCallback((searchTerm?: string) => {
    assetsSavedSearchList({
      queryParams: {
        limit: 10,
        offset: 0,
        ordering: '-created_at',
        title: searchTerm,
        organization: currentUser.getSnapshot()?.organization as string
      }
    })
      .then((resp: PaginatedSavedSearchesList) => {
        setSavedSearches(resp.results || []);
      })
      .catch(err => {
        logger.error(`Error loading saved search. Stack trace: ${err}`);
      });
  }, []);

  const showPromptOptions = useCallback(() => {
    if (selectedPromptOption) return;
    setArePromptOptionsVisible(true);
  }, [selectedPromptOption]);

  const hidePromptOptions = useCallback(() => {
    setArePromptOptionsVisible(false);
  }, []);

  const selectPromptOption = useCallback(
    (option: AiClipPromptOption) => {
      setSelectedPromptOption(option);
      setIsPromptOptionSelected(true);
      setArePromptOptionsVisible(false);
      if (!!prompt) submit();
    },
    [prompt]
  );

  useEffect(() => {
    if (!!selectedPromptOption) inputRef.current?.focus();
  }, [selectedPromptOption]);

  const clearPrompt = useCallback(() => {
    setIsPromptOptionSelected(false);
  }, []);

  const clearPromptOption = useCallback(() => {
    setSelectedPromptOption(null);
    inputRef.current!.value = '';
    setPrompt('');
  }, []);

  const isSearchButtonDisabled = useMemo(() => {
    return !prompt.trim() || isLoading;
  }, [prompt, isLoading]);

  function togglePopover() {
    if (isLoading) return;
    setArePromptOptionsVisible(val => !val);
  }

  const submit = useCallback(
    (e?: React.FormEvent) => {
      e?.preventDefault();
      if (!selectedPromptOption) {
        setSelectedPromptOption(SEARCH_OPTIONS[0]);
        setIsPromptOptionSelected(true);
        setArePromptOptionsVisible(false);
        return;
      }
      if (!isLoading) {
        setIsLoading(true);
        openLoaderDialog();
        trackAiSearch({
          term: prompt,
          contentType: 'Clip'
        });
        assetsSavedSearchCreate({
          body: {
            organization_id: adminAppStore.user?.organization as string,
            search_term: prompt,
            title: prompt
          }
        })
          .then(res => {
            navigate(`/search/${res.id}/clips`);
          })
          .catch(err => {
            try {
              if (err && err.toString() === AI_SEARCH_ERROR_CODES.CLIP_NOT_EXIST_FOR_SEARCH_TERM) {
                openNoResultsDialog();
              } else {
                showCreateError(err);
              }
            } catch (error) {
              showCreateError(err);
            }
          })
          .finally(() => {
            setIsLoading(false);
            closeLoaderDialog();
          });
      }
    },
    [isLoading, prompt, selectedPromptOption, setSelectedPromptOption]
  );

  const showCreateError = useCallback(
    err => {
      logger.error(`Failed creating saved search for ${prompt} search term. Stack trace: ${err}`);
      showErrorToast('Something went wrong while generating clips. Please check your search and try again.');
    },
    [logger, prompt]
  );

  function resetInputForm() {
    closeNoResultsDialog();
    clearPromptOption();
    clearPrompt();
    setIsLoading(false);
  }

  const triggerUploadContent = useCallback(() => {
    resetInputForm();
    if (featureFlags[FeatureFlagKeys.Use_CL_FTUX_Clip_Templates]) {
      navigate('/import');
    } else {
      adminAppStore.commit?.('contentStudio/showNewContentDialog');
    }
  }, [navigate]);

  const debouncedSearch = useDebounce(prompt => loadSavedSearches(prompt), 200);

  useEffect(() => {
    debouncedSearch(prompt);
  }, [prompt]);

  const removeSavedSearchFromList = useCallback((id: string) => {
    setSavedSearches(list => list.filter(item => item.id !== id));
  }, []);

  return (
    <>
      <form className="relative flex min-w-[44%] max-w-3xl items-center justify-center" onSubmit={submit}>
        {arePromptOptionsVisible && (
          <div className="fixed right-0 top-0 z-[70] h-full w-full" onClick={hidePromptOptions} />
        )}

        <div
          className={classnames(
            'relative flex w-full items-center gap-2 rounded-lg border border-slate-300 p-0.5 text-sm text-slate-500 focus-within:border-black focus-within:text-black',
            arePromptOptionsVisible ? 'z-[71]' : 'z-[49]'
          )}
          ref={promptMenu}
        >
          <IconSparkles size={20} className="ml-2.5" />
          <Transition
            appear
            show={isPromptOptionSelected}
            className="overflow-hidden"
            enter="transform duration-700 ease-out"
            enterFrom="max-w-0"
            enterTo="max-w-[500px]"
            leave="transform duration-500 ease-in"
            leaveFrom="max-w-[500px]"
            afterLeave={clearPromptOption}
            leaveTo="max-w-0"
          >
            <button
              type="button"
              className="flex shrink-0 items-center gap-1 whitespace-nowrap rounded-md bg-slate-200 px-2.5 py-1 text-black"
            >
              <div>{selectedPromptOption?.label}</div>
              <Icon
                name={arePromptOptionsVisible ? 'IconChevronUp' : 'IconChevronDown'}
                size={20}
                onClick={togglePopover}
              />
            </button>
          </Transition>
          <div className="no-scrollbar flex h-10 grow items-center gap-2 overflow-x-auto">
            <input
              type="text"
              onFocus={showPromptOptions}
              disabled={isLoading}
              ref={inputRef}
              onChange={e => setPrompt(e.target.value)}
              className="min-w-[100px] grow rounded-lg border-none bg-transparent py-1 outline-none ring-0"
              placeholder={!selectedPromptOption ? 'Generate content from your library...' : undefined}
            />
          </div>
          {!!selectedPromptOption && (
            <IconButton
              icon="IconX"
              size="base"
              variation="text"
              type="button"
              disabled={isLoading}
              trackingId="ai-search-remove-button"
              onClick={clearPrompt}
            />
          )}
          <IconButton
            icon={isLoading ? 'IconLoader2' : 'IconArrowRight'}
            size="base"
            type="submit"
            variation="text"
            trackingId="ai-search-arrow-button"
            disabled={isSearchButtonDisabled}
            buttonClassName={classnames('h-9 w-9', !isSearchButtonDisabled ? 'bg-black' : 'bg-transparent', {
              'bg-slate-100': isLoading
            })}
            iconClassName={classnames(isSearchButtonDisabled ? 'text-slate-400' : 'text-white', {
              'animate-spin': isLoading
            })}
          />

          <Transition
            appear
            show={arePromptOptionsVisible}
            className="absolute top-10 z-40 mt-1 w-full text-black"
            enter="duration-100 ease-out"
            enterFrom="scale-95 opacity-0 origin-bottom-right"
            enterTo="scale-100 opacity-100 origin-bottom-right"
            leave="transition duration-75 ease-out"
            leaveFrom="scale-100 opacity-100 origin-bottom-right"
            leaveTo="scale-95 opacity-0 origin-bottom-right"
          >
            <div className="pt-1.5">
              <div className="relative max-h-72 overflow-y-auto rounded-md border bg-white px-1.5 py-2 shadow-lg">
                {SEARCH_OPTIONS.map(option => {
                  return (
                    <button
                      data-test-id="saved-search-option-button"
                      type="button"
                      onClick={() => selectPromptOption(option)}
                      key={option.label}
                      className={classnames(
                        'z-10 flex w-full items-center gap-2 rounded-lg px-2 py-2.5 text-left text-sm hover:bg-slate-100',
                        selectedPromptOption?.label === option.label ? 'bg-slate-100' : 'bg-white'
                      )}
                    >
                      <Icon name={option.icon} size={20} />
                      {option.label}
                    </button>
                  );
                })}
                {!!savedSearches.length && (
                  <div className="mt-2 flex pl-3 pt-2 text-2xs font-normal text-slate-400">Recent Searches</div>
                )}
                {savedSearches.map(savedSearch => {
                  return (
                    <SavedSearchDropdownItem
                      key={savedSearch.id}
                      savedSearch={savedSearch}
                      onDelete={() => removeSavedSearchFromList(savedSearch.id)}
                    />
                  );
                })}
              </div>
            </div>
          </Transition>
        </div>
      </form>
      <ContentDialog
        isOpen={isNoResultsDialogOpen}
        hideCloseIcon={true}
        size="medium"
        setIsOpen={closeNoResultsDialog}
        disableBackdropClose={true}
      >
        <NoResultsDialog searchTerm={prompt} onClose={resetInputForm} onUpload={triggerUploadContent} />
      </ContentDialog>
      <SavedSearchLoader isOpen={isLoaderDialogOpen} closeDialog={closeLoaderDialog} />
    </>
  );
}

export default memo(SavedSearchInput);
