import {
  formVideoWithAccumulatedWords,
  getCommonBits,
  getIntroVideo,
  getOutroVideo,
  getSectionEndTimesAfterExcludesForUpload,
  getSpeakerPillNameTitleConfig,
  mergeSpeakerAndAnalysisTimesArray
} from './common';
import { isCustomUpload } from '../clipContentUtil';
import { roundToNDecimalPlaces } from '../utils';
import { SharedAPIStore } from '@/stores/sharedAPI/sharedAPI';
import { SpeakerSegmentContextType } from '@/context/SpeakerSegmentContext/SpeakerSegmentContextTypes';
import { getSizeConfig } from '@/libs/sharedAPI/sizeConfig/SizeConfigFactory';
import { AssetConfig, Clip } from '@/domains/asset';
import { speakersAnalysisStore } from '@/stores/speakersAnalysis/speakersAnalysis';
import { getVideoManifestUrl } from '@/stores/core';
import { getCentreCropValues } from '@/Pages/Clip/CanvasPlayer/CanvasPlayerUtils';
import { TranscriptContextType } from '@/context/TranscriptContext/TranscriptContextTypes';

export function getActiveSpeakerLayoutCanvasData({
  clip,
  sharedAPIStoreForClip
}: {
  clip: Clip;
  sharedAPIStoreForClip: SharedAPIStore;
}) {
  const sizeConfig = getSizeConfig(clip.asset_metadata.size, clip.id, clip.asset_metadata.layout, 1);

  const {
    introVideoSource,
    introVideoDuration,
    outroVideoSource,
    outroVideoDuration,
    modifiedExcludes,
    showCaption,
    captions,
    canvas_height,
    canvas_width
  } = getCommonBits(sizeConfig, clip, sharedAPIStoreForClip);

  const background_image = clip.asset_metadata.magicLayout?.backgroundImage;

  const { end: introEnd, introVideo } = getIntroVideo(
    introVideoDuration,
    sizeConfig,
    sharedAPIStoreForClip,
    introVideoSource
  );

  const newVideos: AssetConfig['videos'] = [];

  const shapes: any[] = [];
  const text: any[] = [];

  let canvasVideosShapesText: {
    newVideos: AssetConfig['videos'];
    shapes: any[];
    text: any[];
  } = {
    newVideos: [],
    shapes: [],
    text: []
  };

  if (isCustomUpload()) {
    canvasVideosShapesText = getActiveSpeakerLayoutCanvasDataForUpload(
      sizeConfig,
      introEnd,
      modifiedExcludes,
      showCaption,
      clip,
      sharedAPIStoreForClip,
      canvas_width,
      canvas_height
    );
  } else {
    canvasVideosShapesText = getActiveSpeakerLayoutCanvasDataForRecording(
      sizeConfig,
      introEnd,
      modifiedExcludes,
      showCaption,
      clip,
      sharedAPIStoreForClip
    );
  }

  if (canvasVideosShapesText.newVideos.length) {
    newVideos.push(...canvasVideosShapesText.newVideos);
  }

  if (canvasVideosShapesText.shapes.length) {
    shapes.push(...canvasVideosShapesText.shapes);
  }

  if (canvasVideosShapesText.text.length) {
    text.push(...canvasVideosShapesText.text);
  }

  const { end: outroEnd, outroVideo } = getOutroVideo(
    newVideos[newVideos.length - 1].end,
    outroVideoDuration,
    sizeConfig,
    sharedAPIStoreForClip,
    outroVideoSource
  );

  return {
    canvas_width,
    canvas_height: canvas_height,
    duration: outroEnd,
    background_color: clip.asset_metadata.magicLayout?.backgroundColor || '#000000',
    ...(background_image && { background_image }),
    filename: clip.title,
    videos: [...introVideo, ...newVideos, ...outroVideo],
    captions,
    shapes,
    text
  };
}

function getFinalTimeAnalysisArray(clip: Clip, sharedAPIStoreForClip: SharedAPIStore) {
  const speakersAnalysis = speakersAnalysisStore.getSnapshot();
  const speakersAnalysisForClip = speakersAnalysis[clip.id];
  const timeAnalysis = speakersAnalysisForClip.time_analysis;

  if (!timeAnalysis.length) {
    return {
      newTimeAnalysis: [],
      times: []
    };
  }

  const speakerSegmentStore = sharedAPIStoreForClip.speakerSegmentStore as SpeakerSegmentContextType;
  const captions = speakerSegmentStore.captions;

  let currentSpeaker = speakerSegmentStore.segmentSpeaker[captions[0].srt_index].id;
  let speakerStart = 0;
  let previousStartTime = clip.asset_metadata.start;
  let previousProcessedEndTime = 0;

  const speakerIdWithStartAndEndTimes: { speakerId: string; start: number; end: number; inTimeForMainVideo: number }[] =
    [];

  /**
   * Input: captions
   * Output:
   *  - speakerIdWithStartAndEndTimes => Array of objects which symbolises the active speaker between the start and end times
   * Helpers:
   * - previousProcessedEndTime => To keep track of the previous processed end time to calculate the end time of next current speaker
   * - speakerStart => To relay the end time of one speaker to be the start of the other -> for continuous timeline
   * - currentSpeaker => To keep track of the current speaker in the segment
   */
  captions.forEach((caption, index) => {
    const srtIndex = caption.srt_index;
    const speakerInSegment = speakerSegmentStore.segmentSpeaker[srtIndex];

    if (speakerInSegment.id !== currentSpeaker || captions.length - 1 === index) {
      const end = roundToNDecimalPlaces(speakerStart + caption.processed_end_time! - previousProcessedEndTime, 3);
      speakerIdWithStartAndEndTimes.push({
        speakerId: currentSpeaker,
        start: speakerStart,
        end,
        inTimeForMainVideo: previousStartTime
      });

      currentSpeaker = speakerInSegment.id;
      speakerStart = end;
      previousStartTime = caption.start_time;
      previousProcessedEndTime = caption.processed_end_time!;
    }
  });

  /**
   * Given the active speaker between certain start and end times, use this information
   * to expand the time analysis from facial rec to include the right metadata in the
   * final vars - newTimeAnalysis and times
   * */
  const mergedTimesArray: {
    speakerId: string;
    start: number;
    end: number;
    index: number;
    inTimeForMainVideo: number;
  }[] = mergeSpeakerAndAnalysisTimesArray(
    [...speakerIdWithStartAndEndTimes],
    speakersAnalysisForClip.times.map(t => roundToNDecimalPlaces(t - clip.asset_metadata.start, 3))
  );

  // Final var for config generation - newTimeAnalysis
  const newTimeAnalysis = mergedTimesArray.map(a => ({
    start: a.start,
    speakerId: a.speakerId,
    face_positions: timeAnalysis[a.index].face_positions,
    inTimeForMainVideo: roundToNDecimalPlaces(clip.asset_metadata.start + a.inTimeForMainVideo, 3)
  }));

  // Final var for config generation - times
  const times = mergedTimesArray.map(a => roundToNDecimalPlaces(a.start + clip.asset_metadata.start, 3));

  return {
    newTimeAnalysis,
    times
  };
}

export function getActiveSpeakerLayoutCanvasDataForUpload(
  sizeConfig: ReturnType<typeof getSizeConfig>,
  introVideoDuration: number,
  modifiedExcludes: {
    start: number;
    end: number;
  }[],
  showCaption: boolean,
  clip: Clip,
  sharedAPIStoreForClip: SharedAPIStore,
  canvas_width: number,
  canvas_height: number
) {
  const speakersAnalysis = speakersAnalysisStore.getSnapshot();
  const speakersAnalysisForClip = speakersAnalysis[clip.id];

  const newVideos: AssetConfig['videos'] = [];
  const shapes: any[] = [];
  const text: any[] = [];

  const { newTimeAnalysis, times } = getFinalTimeAnalysisArray(clip, sharedAPIStoreForClip);

  if (newTimeAnalysis.length) {
    const transcriptStore = sharedAPIStoreForClip.transcriptStore;
    const mainPlayerRef = sharedAPIStoreForClip.mainPlayerRef;

    const showSpeakerLabels = clip.asset_metadata.magicLayout?.showSpeakerLabels;

    const sectionEndTimes = getSectionEndTimesAfterExcludesForUpload(
      times,
      newTimeAnalysis,
      modifiedExcludes,
      introVideoDuration,
      clip
    );

    // required to void sections after deletes
    sectionEndTimes.forEach((time, index) => {
      if (time === sectionEndTimes[index - 1]) {
        delete sectionEndTimes[index];
        delete newTimeAnalysis[index];
      }
    });

    let start: number = introVideoDuration;
    let end: number = introVideoDuration;

    // run through time analysis to get the face positions at the said times
    newTimeAnalysis.forEach((analysisPoint, index) => {
      let facePositions = analysisPoint.face_positions;

      if (facePositions.length > 1) {
        facePositions = facePositions.filter(facePosition =>
          speakersAnalysisForClip.speaker_mapping?.[analysisPoint?.speakerId]?.includes(facePosition.face_id)
        );
      }

      end = sectionEndTimes[index];

      if (facePositions.length === 0) {
        const {
          source_crop_x,
          source_crop_y,
          source_crop_width,
          source_crop_height,
          target_crop_x,
          target_crop_y,
          target_crop_width,
          target_crop_height
        } = sizeConfig.getCropPositions({
          videoWidth: sharedAPIStoreForClip.mainPlayerRef?.videoWidth || canvas_width,
          videoHeight: sharedAPIStoreForClip.mainPlayerRef?.videoHeight || canvas_height,
          showCaption,
          shouldUseDefaultLayoutValues: true
        });

        newVideos.push({
          start,
          end,
          x: target_crop_x,
          y: target_crop_y,
          z: 0,
          bounding_width: target_crop_width,
          bounding_height: target_crop_height,
          radius: sizeConfig.getBorderVideoRadius(),
          meta: {
            excludes: modifiedExcludes,
            crop_x: source_crop_x,
            crop_y: source_crop_y,
            crop_width: source_crop_width,
            crop_height: source_crop_height,
            source: getVideoManifestUrl(),
            in_time: clip.asset_metadata.start
          }
        });
        return;
      }

      // for each visible face, add a video
      facePositions.forEach((facePosition, facePositionIndex) => {
        const cropPosition = facePosition.crop_position || facePosition.crop_position_face;

        if (!cropPosition) {
          return;
        }

        // Cropping logic starts here
        const {
          applyNewCropping,
          source_crop_x,
          source_crop_y,
          source_crop_width,
          source_crop_height,
          target_crop_x,
          target_crop_y,
          target_crop_width,
          target_crop_height
        } = sizeConfig.getCropPositions({
          videoWidth: mainPlayerRef?.videoWidth!,
          videoHeight: mainPlayerRef?.videoHeight!,
          showCaption,
          totalVideos: facePositions.length,
          currentVideoNumber: facePositionIndex + 1,
          optionsForNewSpeakerMapping: {
            originalVideoWidth: mainPlayerRef!.videoWidth!,
            originalVideoHeight: mainPlayerRef!.videoHeight!,
            allPositions: facePosition
          }
        });

        const { crop_x, crop_y, crop_height, crop_width } = getCentreCropValues(
          mainPlayerRef!.videoWidth * (cropPosition.bottom_right.x - cropPosition.top_left.x),
          mainPlayerRef!.videoHeight * (cropPosition.bottom_right.y - cropPosition.top_left.y),
          target_crop_width,
          target_crop_height
        );
        // Cropping logic ends here

        newVideos.push({
          start,
          end,
          x: target_crop_x,
          y: target_crop_y,
          z: 0,
          bounding_width: target_crop_width,
          bounding_height: target_crop_height,
          radius: sizeConfig.getBorderVideoRadius(),
          meta: {
            excludes: modifiedExcludes.map(exclude => ({
              start: exclude.start,
              end: exclude.end
            })),
            crop_x: Math.round(
              applyNewCropping ? source_crop_x : cropPosition.top_left.x * mainPlayerRef!.videoWidth + crop_x
            ),
            crop_y: Math.round(
              applyNewCropping ? source_crop_y : cropPosition.top_left.y * mainPlayerRef!.videoHeight + crop_y
            ),
            crop_width: Math.round(applyNewCropping ? source_crop_width : crop_width),
            crop_height: Math.round(applyNewCropping ? source_crop_height : crop_height),
            source: getVideoManifestUrl(),
            in_time: analysisPoint.inTimeForMainVideo
          }
        });

        if (showSpeakerLabels) {
          const speaker = Object.values(transcriptStore.speakersWithDetails).find(speaker =>
            speakersAnalysisForClip.speaker_mapping[speaker.id]?.includes(facePosition.face_id)
          );

          if (!speaker) return;

          const speakerPillNameTitleConfig = getSpeakerPillNameTitleConfig(
            { ...speaker },
            true,
            start,
            end,
            facePositions.length,
            facePositionIndex + 1,
            clip,
            sharedAPIStoreForClip
          );

          if (speakerPillNameTitleConfig?.shapes.length) {
            shapes.push(...speakerPillNameTitleConfig.shapes);
          }

          if (speakerPillNameTitleConfig?.text.length) {
            text.push(...speakerPillNameTitleConfig.text);
          }
        }
      });
      start = end;
    });
  }

  return {
    newVideos,
    shapes,
    text
  };
}

function getActiveSpeakerLayoutCanvasDataForRecording(
  sizeConfig: ReturnType<typeof getSizeConfig>,
  introVideoDuration: number,
  modifiedExcludes: {
    start: number;
    end: number;
  }[],
  showCaption: boolean,
  clip: Clip,
  sharedAPIStoreForClip: SharedAPIStore
) {
  const speakerSegmentStore = sharedAPIStoreForClip.speakerSegmentStore as SpeakerSegmentContextType;

  const transcriptStore = sharedAPIStoreForClip.transcriptStore as TranscriptContextType;

  const newVideos: AssetConfig['videos'] = [];
  const shapes: any[] = [];
  const text: any[] = [];

  if (speakerSegmentStore.captions.length) {
    let currentSpeaker = '';
    const accumulatedWords: any[] = [];
    let start = introVideoDuration;
    let end = 0;

    const showSpeakerLabels = clip.asset_metadata.magicLayout?.showSpeakerLabels;

    // run through captions to get the speaker videos
    if (speakerSegmentStore.captions.length) {
      // {} is used for the last processing
      [...speakerSegmentStore.captions, {} as any]?.forEach(word => {
        if (word.speaker_label !== currentSpeaker) {
          if (accumulatedWords.length > 0) {
            end = roundToNDecimalPlaces(
              introVideoDuration +
                (word?.processed_end_time ?? accumulatedWords[accumulatedWords.length - 1].processed_end_time),
              3
            );

            const speakerDetails = transcriptStore.speakersWithDetails[accumulatedWords[0].speaker_label];
            const video = formVideoWithAccumulatedWords(
              start,
              end,
              accumulatedWords,
              sizeConfig,
              modifiedExcludes,
              speakerDetails.video,
              showCaption,
              speakerDetails.key,
              clip,
              sharedAPIStoreForClip
            );
            newVideos.push(video);
            if (showSpeakerLabels) {
              const speakerPillNameTitleConfig = getSpeakerPillNameTitleConfig(
                speakerDetails,
                false,
                start,
                end,
                1,
                1,
                clip,
                sharedAPIStoreForClip
              );

              if (speakerPillNameTitleConfig?.shapes.length) {
                shapes.push(...speakerPillNameTitleConfig.shapes);
              }

              if (speakerPillNameTitleConfig?.text.length) {
                text.push(...speakerPillNameTitleConfig.text);
              }
            }
            accumulatedWords.splice(0, accumulatedWords.length);
            start = end;
          }
          currentSpeaker = word.speaker_label;
        }
        accumulatedWords.push(word);
      });
    }
  }

  return {
    newVideos,
    shapes,
    text
  };
}
