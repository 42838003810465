import { Fragment, useEffect, useState } from 'react';
import { IconEdit } from '@tabler/icons-react';
import { getListOfVisibleSpeakers } from '../CompositePlayer/CompositePlayerUtils';
import { classnames, getSpeakerImage } from '@/libs/utils';
import Toggle from '@/components/atoms/Toggle';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import Avatar from '@/components/atoms/Avatar/Avatar';
import { updateVisibleSpeakers } from '@/stores/clip';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';

// TODO @dusangc: Remvoe isInline prop with Easy Clip Customizer FF cleanup, it will be always true
export default function SpeakerToggler({ isInline = false }: { isInline?: boolean }) {
  const transcriptStore = useTranscriptContext();
  const { clipId, clipData } = useClipsContext();

  const clipSpeakers = clipData?.asset_metadata?.visible_speakers;
  const [allSpeakers, setAllSpeakers] = useState<SpeakerWithDetails[]>([]);

  useEffect(() => {
    const visible_speakers = getListOfVisibleSpeakers(
      transcriptStore.speakersWithDetails,
      clipData.asset_metadata.start,
      clipData.asset_metadata.end
    );
    if (!clipSpeakers?.length) {
      updateVisibleSpeakers(clipId, visible_speakers);
    }

    setAllSpeakers(visible_speakers);
  }, [transcriptStore.speakersWithDetails, clipSpeakers]);

  function setVisibleSpeaker(speakerKey: string) {
    updateVisibleSpeakers(
      clipId,
      allSpeakers
        .filter(s => clipSpeakers?.find(speaker => speaker.key === s.key) !== undefined)
        .map(s => s.key)
        .includes(speakerKey)
        ? clipSpeakers?.filter(s => s.key !== speakerKey) || []
        : [...(clipSpeakers || []), allSpeakers.find(s => s.key === speakerKey) as any]
    );
  }

  function triggerSpeakerEdit(speakerId?: string) {
    EventBus.dispatch(CustomEvents.OpenContentSettings, { speakerId });
  }

  return (
    <Fragment>
      <div className="my-2 text-sm">Select upto 4 speakers. To show speaker labels match the faces below</div>
      <div
        className={classnames('max-h-[260px] rounded-md', isInline ? '' : 'overflow-y-scroll border border-slate-200')}
      >
        {allSpeakers.map(speaker => {
          const isToggleOn = clipSpeakers?.find(s => s.key === speaker.key) !== undefined;
          const label = `${speaker.first_name} ${speaker.last_name}`;

          return (
            <div className="flex items-center justify-between rounded-md px-3 py-2.5">
              <div className="flex items-center gap-2">
                <div
                  className="group flex w-full cursor-pointer flex-row items-center gap-2"
                  onClick={() => triggerSpeakerEdit(speaker?.id)}
                >
                  <Avatar className="shrink-0" name={label} imageUrl={getSpeakerImage(speaker.profile_picture_url)} />
                  <span className="text-sm">{label}</span>
                  <div className="hidden group-hover:block">
                    <IconEdit size={16} />
                  </div>
                </div>
              </div>
              <Toggle
                on={isToggleOn}
                onToggle={() => {
                  setVisibleSpeaker(speaker.key);
                }}
                disabled={(clipSpeakers?.length === 4 && !isToggleOn) || (clipSpeakers?.length === 1 && isToggleOn)}
              />
            </div>
          );
        })}
      </div>
    </Fragment>
  );
}
