import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';

import useMediaContent from '../Sessions/useMediaContent';
import Button from '@/components/atoms/Button/Button';
import NewContentModal from '@/Pages/Sessions/NewContentModal/NewContentModal';

import { currentUser } from '@/stores/user';
import { imageSrcPath } from '@/libs/utils';
import { CustomEvents } from '@/libs/eventBus/constants';
import EventBus from '@/libs/eventBus/eventBus';
import { disableFirstTimeUser } from '@/stores/firstTimeUserStore';

export default function FirstTimeUploadSplash(): JSX.Element {
  const { mediaContent, fetchData } = useMediaContent();

  const navigate = useNavigate();

  const { isFetching: loadingMediaContent } = useQuery({
    queryKey: 'splash-recent-events',
    queryFn: () => loadMediaContent()
  });

  function loadMediaContent() {
    return fetchData({ organization: currentUser.getSnapshot()?.organization as string, limit: 5 });
  }

  const onContentUploadDone = useCallback(() => {
    disableFirstTimeUser().then(() => {
      navigate('/');
    });
  }, [navigate]);

  useEffect(() => {
    const eventListener = EventBus.on(CustomEvents.UploadContentStarted, ({ projectId, contentId }) => {
      navigate(`/${projectId}/${contentId}/clips`);
    });
    return () => {
      EventBus.off(CustomEvents.UploadContentStarted, eventListener);
    };
  }, [navigate]);

  useEffect(() => {
    const eventListener = EventBus.on(CustomEvents.UploadContentDone, onContentUploadDone);
    return () => {
      EventBus.off(CustomEvents.UploadContentDone, eventListener);
    };
  }, [onContentUploadDone]);

  const handleSampleVideoClick = () => {
    const sampleVideo = mediaContent.filter(
      content => content.media_source_type === 'UPLOAD' && content.is_sample_upload
    );

    if (sampleVideo.length) {
      disableFirstTimeUser().then(() => {
        navigate(`/${sampleVideo[0].project.id}/${sampleVideo[0].id}/clips`);
      });
    }
  };

  return (
    <div className="relative flex h-[calc(100vh-74px)] w-full overflow-hidden bg-slate-200 pr-[-2] shadow-sm">
      <div className="flex grow items-center justify-center p-8 ">
        <div className="z-20 flex h-full max-h-[10rem] min-h-[30rem] w-full min-w-[32rem] flex-col rounded-2xl border border-white bg-white/[0.84] pt-6 shadow-xl backdrop-blur lg:w-1/2 lg:-translate-y-8 lg:translate-x-20">
          <NewContentModal
            title="Turn one video into your next campaign"
            description="Get weeks worth of content out of your webinars, product demos, video podcasts, or any other long-form video content."
          />
        </div>
        <div className="relative hidden w-[60%] -translate-x-12 translate-y-8 lg:block">
          <div className="absolute left-[10%] top-[4%] flex">
            {Array.from({ length: 20 }).map((_, i) => (
              <div
                key={i}
                className="h-2 w-2 animate-pulse rounded-full bg-slate-500"
                style={{ transform: `translateX(${12 * i}px)`, animationDelay: `${100 * i}ms` }}
              />
            ))}
          </div>
          <div className="absolute bottom-[8%] left-[-30%] flex">
            {Array.from({ length: 15 }).map((_, i) => (
              <div
                key={i}
                className="h-2 w-2 animate-pulse rounded-full bg-slate-500"
                style={{ transform: `translateX(${12 * i}px)`, animationDelay: `${100 * i}ms` }}
              />
            ))}
          </div>
          <div className="absolute bottom-[39%] left-[-30%] flex flex-col">
            {Array.from({ length: 15 }).map((_, i) => (
              <div
                key={i}
                className="h-2 w-2 animate-pulse rounded-full bg-slate-500"
                style={{ transform: `translateY(${12 * i}px)`, animationDelay: `${100 * i + 15}ms` }}
              />
            ))}
          </div>
          <div className="absolute z-30 h-full w-full bg-gradient-to-tl from-slate-200/0 via-slate-200/30 to-slate-200/90"></div>
          <img
            src={imageSrcPath('assets/cl-ftux-collage.png')}
            alt="Collage"
            className="relative z-20 w-full rounded-xl"
          />
        </div>
        <div className="absolute bottom-5 right-5 flex items-center space-x-4">
          <div className="text-sm">Don't have a video you're ready to import?</div>
          <Button
            variation="list"
            disabled={loadingMediaContent}
            onClick={handleSampleVideoClick}
            trackingId="first-user-sample-video"
          >
            Try Sample Video
          </Button>
        </div>
      </div>
    </div>
  );
}
