import React, { useEffect, useMemo, useState, useSyncExternalStore } from 'react';
import { IconPlayerPauseFilled, IconPlayerPlayFilled } from '@tabler/icons-react';
import IntroOutroPlayer from './IntroOutroPlayer';
import useIntroRef from './ClipPlayerSections/useIntroRef';
import useOutroRef from './ClipPlayerSections/useOutroRef';
import useMainSectionRef from './ClipPlayerSections/useMainSectionRef';
import ClipPlayerControls from './ClipPlayerControls';
import { ClipPlayerSection } from './ClipPlayerSections/ClipPlayerSectionsTypes';
import CanvasPlayer from '../CanvasPlayer/CanvasPlayer';
import CompositePlayerV2 from '../CompositePlayerV2/CompositePlayerV2';
import { getListOfVisibleSpeakers } from '../CompositePlayer/CompositePlayerUtils';
import MainPlayer from './MainPlayer';
import { getClipPlayerElementId, isSpeakerChanged } from './ClipPlayerUtils';
import { ClipLayoutStatus } from '../MagicLayout/constants';
import PlayerErrorState from './PlayerErrorState';
import useClipTranscriptPlayPause from './ClipPlayerControls/useClipTranscriptPlayPause';
import { getDeletedSecondsBeforeTime } from './ClipPlayerControls/utils';
import CompositePlayerImages from '../CompositePlayerV2/CompositePlayerImages';
import ClipTimelineWithActionBar from './ClipTimeline/ClipTimelineWithActionBar';
import ClipTimelineWithActionBarV2 from './ClipTimelineV2/ClipTimelineWithActionBar';
import ClipCustomizerMenu from './ClipCustomizer/ClipCustomizerMenu';
import { TIMELINE_DISPLAY_TYPE } from './ClipTimelineV2/ClipTimelineTypes';
import AnalysisOverlay from './AnalysisOverlay';
import FailedOverlay from './FailedOverlay';
import { useActiveSpeakerMatchCondition } from './ClipCustomizer/useActiveSpeakerMatchCondition';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import { updatePlayerStoreWithId } from '@/stores/playerV2';
import { useSpeakerSegmentContext } from '@/context/SpeakerSegmentContext/SpeakerSegmentContext';
import { classnames } from '@/libs/utils';
import Loader from '@/components/atoms/Loader';
import { initSharedAPI } from '@/stores/sharedAPI/sharedAPI';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';

export default function ClipPlayer({
  showNewTimeline = false,
  inlineEditEnabled = false,
  playerClasses = '',
  timelineDisplayType = TIMELINE_DISPLAY_TYPE.PROGRESS_BAR
}: {
  showNewTimeline?: boolean;
  inlineEditEnabled?: boolean;
  playerClasses?: string;
  timelineDisplayType?: TIMELINE_DISPLAY_TYPE;
}) {
  const transcriptStore = useTranscriptContext();

  const { clipId, clipData, clipVideosLoading, setClipVideosLoading, sharedAPIStore, playerStore, layoutStatus } =
    useClipsContext();
  const clipMetadata = useMemo(() => clipData.asset_metadata, [clipData.asset_metadata]);

  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);
  const isCaptionsOverlayDisabled = featureFlags[FeatureFlagKeys.Use_CL_Captions_Overlay] === false;
  const isNewTimelineEnabled = featureFlags[FeatureFlagKeys.Use_CL_New_Timeline];
  const [showSpeakerLayoutProcessingOverlay, showSpeakerLayoutFailedOverlay] = useMemo(
    () => [layoutStatus === 'PROCESSING', layoutStatus === 'FAILED'],
    [layoutStatus]
  );

  useEffect(() => {
    return () => {
      initSharedAPI(clipId);
    };
  }, []);

  useEffect(() => {
    const clipElement = document.getElementById(getClipPlayerElementId(clipId));
    const url = new URL(window.location.href);
    if (clipElement && url.hash === `#${getClipPlayerElementId(clipId)}`) {
      clipElement.scrollIntoView({ behavior: 'smooth' });
      clipElement.classList.add('border-2', 'border-blue-500', 'animate-pulse');
      setTimeout(() => {
        clipElement.classList.remove('border-2', 'border-blue-500', 'animate-pulse');
        url.hash = '';
        window.history.replaceState({}, '', url.toString());
      }, 2000);
    }
  }, [clipId]);

  const sections = useMemo(
    () =>
      [
        ...(clipMetadata.intro ? ['intro'] : []),
        'main',
        ...(clipMetadata.outro ? ['outro'] : [])
      ] as ClipPlayerSection[],
    [clipMetadata.intro, clipMetadata.outro]
  );

  const [currentSection, setCurrentSection] = useState<ClipPlayerSection>(sections[0]);
  const [shouldPlayCurrentSection, setShouldPlayCurrentSection] = useState(false);
  const [seekTime, setSeekTime] = useState<number>();
  const [isLoadFailed, setIsLoadFailed] = useState(false);

  useEffect(() => {
    if (isLoadFailed) return;
    if (introRef.current) resetClipOnSectionChange(introRef.current, onIntroEnd);
    if (outroRef.current) resetClipOnSectionChange(outroRef.current, onOutroEnd);
    if (mainSectionRef.current) resetClipOnSectionChange(mainSectionRef.current, pauseMainSection);
    setCurrentSectionAndPlay(sections[0], false, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections]);

  function resetClipOnSectionChange(sectionPlayer: HTMLVideoElement, onEndFunction: () => void) {
    sectionPlayer.pause();
    sectionPlayer.currentTime = sectionPlayer.id === 'mainPlayer' ? clipMetadata.start : 0;
    onEndFunction();
  }
  const speakers = useMemo(() => transcriptStore.speakersWithDetails, [transcriptStore.speakersWithDetails]);

  // Only render composite player for live hybrid broadcasts
  const showCompositePlayer = useMemo(() => {
    return (
      clipData.content?.media_source_type !== 'UPLOAD' &&
      clipData.content?.broadcast_type === 'LIVE_HYBRID' &&
      // Ensure there are at least one speaker video coming from agora
      Object.values(speakers).some(speaker => speaker.video.url)
    );
  }, [clipData.content?.media_source_type, clipData.content?.broadcast_type, speakers]);

  const playIndividualRecording = useMemo(
    () => (clipMetadata.layout === 'SPEAKER' || clipMetadata.layout === 'GRID') && showCompositePlayer,
    [clipMetadata.layout, showCompositePlayer]
  );

  const size = clipMetadata.size;

  const setCurrentSectionAndPlay = (section: ClipPlayerSection, shouldPlay: boolean, time?: number) => {
    setCurrentSection(section);
    setShouldPlayCurrentSection(shouldPlay);
    setSeekTime(time);
    if (time && playIndividualRecording) {
      secondaryPlayers.forEach(player => {
        if (player.current) {
          player.current!.currentTime =
            (time ?? 0) - transcriptStore.speakersWithDetails[player.current!.id].video.start;
        }
      });
    }
  };

  const outroPlayerProps = useOutroRef(setCurrentSectionAndPlay, clipMetadata);
  const { outroRef, onOutroTimeUpdate, onOutroEnd, onLoadedOutroData, playOutroSection, pauseOutroSection } =
    outroPlayerProps;

  const mainPlayerProps = useMainSectionRef(setCurrentSectionAndPlay, clipMetadata, playIndividualRecording);
  const {
    mainSectionRef,
    onMainSectionTimeUpdate,
    playMainSection,
    pauseMainSection,
    secondaryPlayers,
    setSecondaryPlayers,
    updateSecondaryPlayerTime,
    mainSectionCurrentTime,
    speakerImageRefs,
    setSpeakerImageRefs
  } = mainPlayerProps;

  const introPlayerProps = useIntroRef(setCurrentSectionAndPlay);
  const { introRef, onIntroTimeUpdate, onIntroEnd, onLoadedIntroData, playIntroSection, pauseIntroSection } =
    introPlayerProps;

  const { isPaused, playCurrentSection, pauseCurrentSection } = useClipTranscriptPlayPause({
    currentSection,
    outroPlayerProps,
    mainPlayerProps,
    introPlayerProps
  });

  /**
   * This effect is responsible for setting the time of the current section and playing it
   * This is generally used when the user seeks to a specific time in the timeline of intro, outro or main section
   */
  useEffect(() => {
    if (currentSection === 'main') {
      const currentTime = (seekTime ?? 0) + clipMetadata.start;
      // Adjust seek time in case of deleted times. If we delete 10-15 and seek to 12, it should seek to 17 actually
      mainSectionRef.current!.currentTime = currentTime + getDeletedSecondsBeforeTime(currentTime, clipId);
      onMainSectionTimeUpdate();
      if (shouldPlayCurrentSection) {
        pauseOutroSection();
        pauseIntroSection();
        playMainSection();
        setShouldPlayCurrentSection(false);
      }
    }

    if (currentSection === 'intro') {
      introRef.current!.currentTime = seekTime ?? 0;
      onIntroTimeUpdate();
      if (shouldPlayCurrentSection) {
        pauseOutroSection();
        playIntroSection();
        pauseMainSection();
        setShouldPlayCurrentSection(false);
      }
    }

    if (currentSection === 'outro') {
      outroRef.current!.currentTime = seekTime ?? 0;

      onOutroTimeUpdate();
      if (shouldPlayCurrentSection) {
        pauseMainSection();
        pauseIntroSection();
        playOutroSection();
        setShouldPlayCurrentSection(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSection, seekTime]);

  const speakerSegmentContextData = useSpeakerSegmentContext();

  const [currentSpeaker, setCurrentSpeaker] = useState<SpeakerWithDetails>(Object.values(speakers)[0]);

  const allAvailableSpeakers = useMemo(() => {
    const allAvailableSpeakers = getListOfVisibleSpeakers(speakers, clipMetadata.start, clipMetadata.end);
    return allAvailableSpeakers;
  }, [speakers, clipMetadata.start, clipMetadata.end]);

  const passiveLoadCompositePlayer = useMemo(() => {
    if (!['SPEAKER', 'GRID'].includes(clipMetadata.layout)) return true;
    return (
      clipData.layout_status['SPEAKER'].status === ClipLayoutStatus.Done ||
      clipData.layout_status['GRID'].status === ClipLayoutStatus.Done
    );
  }, [clipData.layout_status]);

  useEffect(() => {
    if (!speakerSegmentContextData) return;

    const newCurrentSpeaker =
      speakers[speakerSegmentContextData!.segmentSpeaker[playerStore.currentSrtIndex]?.key] ?? currentSpeaker;

    if (isSpeakerChanged(newCurrentSpeaker, currentSpeaker)) {
      setCurrentSpeaker(newCurrentSpeaker);
    }
  }, [speakerSegmentContextData, playerStore.currentSrtIndex, currentSpeaker, speakers]);

  /**
   * This effect takes care of enabling the clip editor page once all the speaker videos are loaded
   */
  useEffect(() => {
    let isLoadingState = false;

    // Set it to true if any of the speaker videos are not loaded
    // Or main player is not loaded
    // Or speaker info is not loaded
    if (
      allAvailableSpeakers.some(speaker => {
        return !sharedAPIStore.speakerMap[speaker.key];
      }) ||
      !sharedAPIStore.mainPlayerRef ||
      !Object.keys(speakers).length ||
      transcriptStore.usedSpeakers.some(speaker => {
        return speaker.profile_picture_url && !sharedAPIStore.speakerImageMap[speaker.key];
      })
    ) {
      isLoadingState = true;
    }

    setClipVideosLoading(isLoadingState);
  }, [sharedAPIStore.speakerMap, sharedAPIStore.speakerImageMap, sharedAPIStore.mainPlayerRef, speakers, isLoadFailed]);

  const startTimesAtSrtIndex = useMemo(() => {
    if (transcriptStore.timeSrtIndexArray.length === 0) {
      return [];
    }
    return transcriptStore.timeSrtIndexArray.map(a => a.startTime);
  }, [transcriptStore.timeSrtIndexArray]);

  /**
   * Update the player srt index based on time and the srt index of the words
   */
  useEffect(() => {
    if (startTimesAtSrtIndex.length === 0) return;

    const index = startTimesAtSrtIndex.findLastIndex(time => time <= mainSectionCurrentTime);

    updatePlayerStoreWithId(clipId, { currentSrtIndex: transcriptStore.timeSrtIndexArray[index]?.srtIndex });
  }, [mainSectionCurrentTime, startTimesAtSrtIndex, transcriptStore.timeSrtIndexArray]);

  function showErrorState() {
    setIsLoadFailed(true);
  }
  const isEasyClipCustomizerEnabled = featureFlags[FeatureFlagKeys.Use_CL_Easy_Clip_Customizer];

  function onClick() {
    if (isPaused) {
      playCurrentSection();
    } else {
      pauseCurrentSection();
    }
  }

  const showPlayerLoader = useMemo(
    () => passiveLoadCompositePlayer && clipVideosLoading,
    [passiveLoadCompositePlayer, clipVideosLoading]
  );

  const showCanvasPlayer = useMemo(() => {
    const isLayoutAudiogram = clipMetadata.layout === 'AUDIOGRAM';
    const isGoldcastLiveRecording = secondaryPlayers.length > 0;
    const areOverlaysVisible = showSpeakerLayoutProcessingOverlay || showSpeakerLayoutFailedOverlay;

    return (
      (!showCompositePlayer || isLayoutAudiogram || isGoldcastLiveRecording) &&
      !clipVideosLoading &&
      !areOverlaysVisible
    );
  }, [
    clipMetadata.layout,
    clipVideosLoading,
    secondaryPlayers.length,
    showCompositePlayer,
    showSpeakerLayoutProcessingOverlay,
    showSpeakerLayoutFailedOverlay
  ]);

  const isClipCustomizerMenuVisible = useMemo(() => {
    return isEasyClipCustomizerEnabled && inlineEditEnabled && !clipVideosLoading && !clipData.locked;
  }, [clipData.locked, clipVideosLoading, inlineEditEnabled, isEasyClipCustomizerEnabled]);

  const { isSpeakerIdentificationMandatory } = useActiveSpeakerMatchCondition();

  const areClipPlayerControlsDisabled = useMemo(
    () => showPlayerLoader || showSpeakerLayoutProcessingOverlay || showSpeakerLayoutFailedOverlay,
    [showPlayerLoader, showSpeakerLayoutProcessingOverlay, showSpeakerLayoutFailedOverlay]
  );

  useEffect(() => {
    const isActiveSpeakerLayout = clipMetadata.layout === 'SPEAKER';
    if (!areClipPlayerControlsDisabled && isSpeakerIdentificationMandatory && isActiveSpeakerLayout) {
      EventBus.dispatch(CustomEvents.OpenSpeakersIdentification, {
        postIdentifyCallback: () => {
          EventBus.dispatch(CustomEvents.ComputeFrame);
        },
        layout: 'SPEAKER',
        isInlineIdentification: true
      });
    }
  }, [areClipPlayerControlsDisabled, isSpeakerIdentificationMandatory]);

  return (
    <div
      id={getClipPlayerElementId(clipId)}
      className="flex h-full w-full grow flex-col items-center justify-center overflow-hidden"
    >
      <div className={classnames('flex h-full w-full items-center justify-center p-4', playerClasses)}>
        <div
          className={classnames('flex h-full max-w-full items-center justify-center', {
            'aspect-video': size === 'LANDSCAPE',
            'pr-24': isEasyClipCustomizerEnabled && inlineEditEnabled && size === 'LANDSCAPE',
            'aspect-square': size === 'SQUARE',
            'aspect-[9/16]': size === 'PORTRAIT'
          })}
        >
          <div
            className={classnames('group relative flex w-full items-center justify-center overflow-hidden', {
              'aspect-video': size === 'LANDSCAPE',
              'aspect-square': size === 'SQUARE',
              'aspect-[9/16]': size === 'PORTRAIT'
            })}
          >
            {clipMetadata.intro && (
              <IntroOutroPlayer
                videoRef={introRef}
                videoProps={{
                  src: clipMetadata.intro,
                  onTimeUpdate: onIntroTimeUpdate,
                  onEnded: onIntroEnd,
                  onLoadedData: onLoadedIntroData,
                  id: 'player-intro-section'
                }}
                type="intro"
                parentClassName={`absolute top-0 h-full w-full ${currentSection === 'intro' ? '' : 'hidden'}`}
                data-testid="player-intro-section"
              />
            )}

            {isLoadFailed ? (
              <PlayerErrorState />
            ) : (
              <div
                className={`h-full w-full ${currentSection === 'main' ? '' : 'hidden'}`}
                id="player-main-section"
                data-testid="player-main-section"
              >
                <MainPlayer
                  id="mainPlayer"
                  videoRef={mainSectionRef}
                  onLoadFailed={showErrorState}
                  isHidden={!showCompositePlayer || secondaryPlayers.length > 0}
                  broadcastId={clipData.content?.id ?? ''}
                  eventId={clipData.content?.project?.id ?? ''}
                />

                {showCompositePlayer && (
                  <CompositePlayerV2
                    updateSecondaryPlayerTime={updateSecondaryPlayerTime}
                    secondaryPlayers={secondaryPlayers}
                    allAvailableSpeakers={allAvailableSpeakers}
                    setSecondaryPlayers={setSecondaryPlayers}
                    onLoadFailed={showErrorState}
                    passiveLoadCompositePlayer={passiveLoadCompositePlayer}
                  />
                )}

                <CompositePlayerImages speakerImageRefs={speakerImageRefs} setSpeakerImageRefs={setSpeakerImageRefs} />

                {showCanvasPlayer && (
                  <CanvasPlayer
                    mainPlayer={mainSectionRef}
                    secondaryPlayers={secondaryPlayers}
                    currentSpeaker={currentSpeaker}
                    loading={clipVideosLoading}
                    inlineEditEnabled={inlineEditEnabled}
                  />
                )}
              </div>
            )}

            {clipMetadata.outro && (
              <IntroOutroPlayer
                videoRef={outroRef}
                videoProps={{
                  src: clipMetadata.outro,
                  onTimeUpdate: onOutroTimeUpdate,
                  onEnded: onOutroEnd,
                  onLoadedData: onLoadedOutroData
                }}
                type="outro"
                parentClassName={`absolute top-0 h-full w-full ${currentSection === 'outro' ? '' : 'hidden'}`}
                data-testid="player-outro-section"
              />
            )}

            {showSpeakerLayoutProcessingOverlay ? (
              <AnalysisOverlay />
            ) : showSpeakerLayoutFailedOverlay ? (
              <FailedOverlay />
            ) : showPlayerLoader ? (
              <div
                className="absolute inset-0 overflow-hidden rounded-md bg-slate-100"
                data-testid="composite-player-loader"
                id="composite-player-loader"
              >
                <div className="absolute inset-0 flex animate-pulse items-center justify-center overflow-hidden rounded-md bg-gray-200">
                  <Loader />
                </div>
              </div>
            ) : clipMetadata.layout !== 'AUDIOGRAM' && isCaptionsOverlayDisabled && !isNewTimelineEnabled ? (
              <div
                className="absolute top-0 hidden h-full w-full items-center justify-center bg-black/30 group-hover:flex"
                onClick={onClick}
              >
                {isPaused ? (
                  <IconPlayerPlayFilled className="cursor-pointer text-white" size={45} />
                ) : (
                  <IconPlayerPauseFilled className="cursor-pointer text-white" size={45} />
                )}
              </div>
            ) : null}
          </div>
        </div>
        {isClipCustomizerMenuVisible && <ClipCustomizerMenu />}
      </div>

      {timelineDisplayType === TIMELINE_DISPLAY_TYPE.PROGRESS_BAR ? (
        <ClipPlayerControls
          clipMetadata={clipMetadata}
          sections={sections}
          currentSection={currentSection}
          setCurrentSectionAndPlay={setCurrentSectionAndPlay}
          outroPlayerProps={outroPlayerProps}
          mainPlayerProps={mainPlayerProps}
          introPlayerProps={introPlayerProps}
          disabled={areClipPlayerControlsDisabled}
        />
      ) : showNewTimeline ? (
        <ClipTimelineWithActionBar
          sections={sections}
          currentSection={currentSection}
          setCurrentSectionAndPlay={setCurrentSectionAndPlay}
          disabled={areClipPlayerControlsDisabled}
          introPlayerProps={introPlayerProps}
          mainPlayerProps={mainPlayerProps}
          outroPlayerProps={outroPlayerProps}
        />
      ) : (
        <ClipTimelineWithActionBarV2
          sections={sections}
          currentSection={currentSection}
          setCurrentSectionAndPlay={setCurrentSectionAndPlay}
          disabled={areClipPlayerControlsDisabled}
          introPlayerProps={introPlayerProps}
          mainPlayerProps={mainPlayerProps}
          outroPlayerProps={outroPlayerProps}
        />
      )}
    </div>
  );
}
