import React, { useCallback, useEffect, useMemo, useRef, useState, useSyncExternalStore } from 'react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { IconEdit } from '@tabler/icons-react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getSelectionHighlightSpecs,
  getSelectionDuration,
  getWordIdFromElement,
  getParagraphIndexByTime,
  parseTranscriptToParagraphs
} from './utils';
import SpeakerRow from './SpeakerRow';
import TranscriptWord from './TranscriptWord';
import { HighlightedSearchParagraph, TranscriptParagraph, TranscriptSelection } from './types';
import TranscriptSkeleton from './TranscriptSkeleton';
import {
  CLIP_MAX_DURATION_ERROR,
  CLIP_MIN_DURATION_ERROR,
  CLIP_MIN_LENGTH,
  VIRUAL_CONTAINER_EXTEND_BY_SIZE
} from './constants';
import useTranscriptHighlightHook from './useTranscriptHighlightHook';
import TranscriptSearch from './TranscriptSearch';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import { TranscriptContextType } from '@/context/TranscriptContext/TranscriptContextTypes';
import { classnames, preventDefault } from '@/libs/utils';
import Button from '@/components/atoms/Button/Button';
import { play, player, updatePlayerTime } from '@/stores/player';
import { Word, WordType } from '@/domains/transcript';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import useElementScrolledHook from '@/hooks/useElementScrolledHook';

export default function FullTranscript({ onCreateClip }: { onCreateClip: (words: Word[]) => Promise<void> }) {
  const navigate = useNavigate();
  const { eventId, broadcastId } = useParams<{ eventId: string; broadcastId: string }>();

  const virtuoso = useRef<VirtuosoHandle>(null);
  const container = useRef<HTMLDivElement>(null);
  const createClipWrapper = useRef<HTMLDivElement>(null);
  const createClipPopup = useRef<HTMLDivElement>(null);
  const playerStore = useSyncExternalStore(player.subscribe, player.getSnapshot);
  const [transcriptSelection, setTranscriptSelection] = useState<TranscriptSelection | null>();
  const [anchorNode, setAnchorNode] = useState<Node | null>(null);
  const [focusNode, setFocusNode] = useState<Node | null>(null);
  const [highlightedParagraph, setHighlightedParagraph] = useState<HighlightedSearchParagraph | null>(null);
  const transcriptStore = useTranscriptContext() as TranscriptContextType;
  const [isCreatingClip, setIsCreatingClip] = useState<boolean | undefined>(undefined);
  const transcript = useMemo(() => transcriptStore.transcript, [transcriptStore.transcript]);
  const { isScrolled, handleOnScroll } = useElementScrolledHook();

  const paragraphs: TranscriptParagraph[] = useMemo(() => {
    return parseTranscriptToParagraphs(transcript, transcriptStore?.speakersWithDetails);
  }, [transcript, transcriptStore?.speakersWithDetails]);

  const { highlightActiveWord } = useTranscriptHighlightHook();

  useEffect(() => {
    highlightActiveWord(container, playerStore);
  }, [playerStore.currentTime, playerStore.currentSrtIndex, playerStore.duration, playerStore.paused]);

  const playFromTime = useCallback(
    (time: number) => {
      if (playerStore.paused && time) {
        updatePlayerTime(time);
        play(time);
      }
    },
    [playerStore]
  );

  /**
   * playFromWord is different from playFromTime as it does not consider if player is paused or not
   */
  const playFromWord = useCallback((startTime: number) => {
    updatePlayerTime(startTime);
    play(startTime);
  }, []);

  const scrollToVirtuosoIndex = useCallback(({ index }: { index: number }) => {
    virtuoso?.current?.scrollToIndex({
      index,
      align: 'center',
      behavior: 'auto'
    });
  }, []);

  const onTimelineChanged = useCallback(
    ({ time, startPlaying }: { time: number; startPlaying: boolean }) => {
      const closestStart = transcript.reduce((prev, { start_time: curr, type }) => {
        return Math.abs(curr - time) < Math.abs(prev - time) && type !== WordType.Punctuation ? curr : prev;
      }, 0);
      const paragraphIndex = getParagraphIndexByTime(closestStart, paragraphs);
      if (paragraphIndex >= 0) {
        scrollToVirtuosoIndex({ index: paragraphIndex });
        updatePlayerTime(time);
        if (startPlaying) playFromTime(time);
      }
    },
    [paragraphs, transcript, scrollToVirtuosoIndex, playFromTime]
  );

  const showCreateClipPopup = useCallback((selectionWords: Word[], left: number, top: number) => {
    if (selectionWords.length < CLIP_MIN_LENGTH) {
      cleanUpSelection();
      return;
    }

    setTranscriptSelection({
      words: selectionWords,
      duration: getSelectionDuration(selectionWords)
    });

    const element = createClipPopup.current;
    if (element) {
      element.classList.remove('invisible');
      element.style.left = `${left}px`;
      element.style.top = `${top}px`;
    }
  }, []);

  const setFocusNodeFromSelection = useCallback(() => {
    const selection = document.getSelection();

    if (!selection?.focusNode?.parentElement?.attributes?.getNamedItem('data-word-id')?.value) {
      return;
    }

    if (!focusNode) {
      setFocusNode(selection.focusNode);
    }
  }, [focusNode]);

  const onPointerUp = useCallback(
    (ev: MouseEvent) => {
      if (!createClipWrapper.current?.contains(ev.target as Node)) {
        setTranscriptSelection(null);
      }

      setFocusNodeFromSelection();
    },
    [setFocusNodeFromSelection]
  );

  const onKeyUp = useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key === 'Escape') {
        cleanUpSelection();
        return;
      }

      if (ev.shiftKey && ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(ev.key)) {
        setFocusNodeFromSelection();
      }
    },
    [setFocusNodeFromSelection]
  );

  useEffect(() => {
    if (isCreatingClip === undefined || isCreatingClip) {
      return;
    }

    navigate(`/${eventId}/${broadcastId}/clips`);
  }, [isCreatingClip, eventId, broadcastId]);

  useEffect(() => {
    // Disable scroll when create clip popup is visible
    const virtuosoContainer = document.querySelector(`[data-test-id="virtuoso-scroller"]`);
    if (transcriptSelection) {
      virtuosoContainer?.addEventListener('scroll', preventDefault, false);
      virtuosoContainer?.addEventListener('mousewheel', preventDefault, false);
      virtuosoContainer?.addEventListener('touchmove', preventDefault, false);
    }
    return () => {
      if (transcriptSelection) {
        virtuosoContainer?.removeEventListener('scroll', preventDefault, false);
        virtuosoContainer?.removeEventListener('mousewheel', preventDefault, false);
        virtuosoContainer?.removeEventListener('touchmove', preventDefault, false);
      }
    };
  }, [transcriptSelection]);

  function createClip() {
    if (transcriptSelection?.words && transcriptSelection.words.length) {
      setIsCreatingClip(true);
      onCreateClip(transcriptSelection.words).then(() => {
        cleanUpSelection();
        setIsCreatingClip(false);
      });
    }
  }

  const setAnchorNodeFromSelection = useCallback(() => {
    const selection = document.getSelection();

    if (!selection?.anchorNode?.parentElement?.attributes?.getNamedItem('data-word-id')?.value) {
      return;
    }

    if (!anchorNode) {
      setAnchorNode(selection.anchorNode);
    }
  }, [anchorNode]);

  useEffect(() => {
    if (!anchorNode?.parentElement || !focusNode?.parentElement) {
      return;
    }

    if (anchorNode === focusNode) {
      const wordId = getWordIdFromElement(anchorNode.parentElement);
      if (!isNaN(wordId)) {
        playFromWord(transcript[wordId]?.start_time);
      }
      return;
    }

    const wordBounds = getSelectionHighlightSpecs(anchorNode, focusNode);

    if (!wordBounds) {
      return;
    }

    const wordsToSelect = transcript.slice(wordBounds.startIndex, wordBounds.endIndex + 1);

    let addendum = 0;
    if (focusNode.compareDocumentPosition(anchorNode) === Node.DOCUMENT_POSITION_PRECEDING) {
      addendum = 20;
    } else {
      addendum = -60; // with height of create clip popup
    }

    const boundingRect = focusNode.parentElement.getBoundingClientRect();
    const x = boundingRect.x;
    const y = boundingRect.y + addendum;

    showCreateClipPopup(wordsToSelect, x, y);
  }, [anchorNode, focusNode, transcript, playFromWord, showCreateClipPopup]);

  const onPointerDown = useCallback((e: MouseEvent) => {
    if (!createClipWrapper.current?.contains(e.target as Node)) {
      cleanUpSelection();
    }
  }, []);

  function cleanUpSelection() {
    createClipPopup.current?.classList.add('invisible');
    document.getSelection()?.removeAllRanges();
    setTranscriptSelection(null);
    setAnchorNode(null);
    setFocusNode(null);
  }

  useEffect(() => {
    const eventListener = EventBus.on(CustomEvents.TimelineChanged, onTimelineChanged);

    document.addEventListener('pointerup', onPointerUp);
    document.addEventListener('selectionchange', setAnchorNodeFromSelection);
    document.addEventListener('pointerdown', onPointerDown);
    document.addEventListener('keyup', onKeyUp);

    return () => {
      EventBus.off(CustomEvents.TimelineChanged, eventListener);

      document.removeEventListener('pointerup', onPointerUp);
      document.removeEventListener('selectionchange', setAnchorNodeFromSelection);
      document.removeEventListener('pointerdown', onPointerDown);
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [transcript, onPointerUp, onTimelineChanged, onPointerDown, setAnchorNodeFromSelection, onKeyUp]);

  const handleEditSpeaker = useCallback(speakerId => {
    EventBus.dispatch(CustomEvents.OpenContentSettings, { speakerId });
  }, []);

  return (
    <React.Fragment>
      <div className="z-20 flex h-full w-[34rem] py-2 transition-all duration-[400ms] ease-in-out">
        <div
          className="relative w-full overflow-hidden rounded-2xl border !border-slate-100 bg-white shadow-xl shadow-slate-200/10"
          ref={container}
          data-testid="transcript-container"
        >
          <div
            className={classnames(
              'sticky top-0 z-20 flex h-14 w-full items-center justify-between bg-white px-5 py-4 transition-all duration-150 ease-in-out',
              {
                'shadow-lg shadow-slate-900/[0.075]': isScrolled
              }
            )}
          >
            <span className="select-none text-lg font-medium leading-tight">Transcript</span>
            <TranscriptSearch
              paragraphs={paragraphs}
              onOccurrencesChange={scrollToVirtuosoIndex}
              setHighlightedParagraph={setHighlightedParagraph}
            />
          </div>
          {!paragraphs.length ? (
            <TranscriptSkeleton />
          ) : (
            <>
              <Virtuoso
                overscan={{
                  main: VIRUAL_CONTAINER_EXTEND_BY_SIZE,
                  reverse: VIRUAL_CONTAINER_EXTEND_BY_SIZE
                }}
                // 93% height because of search
                style={{
                  height: `${paragraphs.length ? '93%' : '0px'}`,
                  overflowX: 'hidden',
                  marginLeft: '1.25rem',
                  marginRight: '0px'
                }}
                className={classnames('leading-[25px] text-slate-700 selection:rounded-sm  selection:text-white')}
                data={paragraphs}
                ref={virtuoso}
                onScroll={handleOnScroll}
                itemContent={(index: number, paragraph: TranscriptParagraph) => {
                  const prevParagraph = paragraphs[index - 1];
                  const nextParagraph = paragraphs[index + 1];

                  if (paragraph.speakerDetails) {
                    return (
                      <SpeakerRow
                        speakerDetails={paragraph.speakerDetails}
                        index={index}
                        wordId={paragraphs[index + 1]?.words[0]?.index}
                        handleEditSpeaker={() => handleEditSpeaker(paragraph.speakerDetails?.speaker?.id)}
                      />
                    );
                  }

                  if (paragraph.words) {
                    return (
                      <div
                        className={classnames('pl-16 pr-4', {
                          '-mt-10': prevParagraph?.speakerDetails,
                          'pb-3': nextParagraph?.words,
                          'pb-8':
                            nextParagraph?.speakerDetails || !(nextParagraph?.words || nextParagraph?.speakerDetails)
                        })}
                      >
                        {prevParagraph?.speakerDetails ? (
                          <div className="mb-2 select-none leading-tight">
                            <button
                              data-testid="transcript-speaker-name-button"
                              className="group flex items-center space-x-1 text-sm text-slate-700"
                              onClick={() => handleEditSpeaker(prevParagraph?.speakerDetails?.speaker?.id)}
                            >
                              <span>{`${prevParagraph?.speakerDetails?.speaker?.first_name} ${prevParagraph?.speakerDetails?.speaker.last_name}`}</span>
                              <IconEdit className="hidden h-4 w-4 group-hover:flex" />
                            </button>
                            <div className="text-xs text-slate-500">
                              {prevParagraph?.speakerDetails?.start} - {prevParagraph?.speakerDetails?.end}
                            </div>
                          </div>
                        ) : null}
                        {paragraph.words?.map((word, i) => {
                          return (
                            <React.Fragment key={word.content + i}>
                              <TranscriptWord
                                word={word}
                                foundWords={highlightedParagraph?.index === index ? highlightedParagraph?.words : []}
                              />
                            </React.Fragment>
                          );
                        })}
                      </div>
                    );
                  }

                  return null;
                }}
              />
            </>
          )}
        </div>
      </div>
      <div
        ref={createClipPopup}
        className="invisible absolute right-0 z-50 mt-2 w-56 origin-top-right select-none rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-[0.05] focus:outline-none"
      >
        <div className="flex w-full items-center justify-between space-x-1.5 p-2">
          <div className="flex grow items-center justify-center text-center">
            <div className="text-xs text-slate-500">{transcriptSelection?.duration}</div>
          </div>
          <div ref={createClipWrapper}>
            <Button
              variation="filled"
              trackingId="create-clip-button"
              onClick={createClip}
              className={classnames({ '!cursor-progress': isCreatingClip })}
              disabled={
                transcriptSelection?.duration === CLIP_MIN_DURATION_ERROR ||
                transcriptSelection?.duration === CLIP_MAX_DURATION_ERROR ||
                isCreatingClip
              }
            >
              {isCreatingClip ? 'Creating...' : 'Create Clip'}
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
