import { memo, useCallback, useEffect, useMemo, useRef, useState, useSyncExternalStore } from 'react';
import CaptionsMenu from './CanvasPlayerDraggableCaptions/CaptionsMenu/CaptionsMenu';
import CanvasPlayerCaptions2 from './CanvasPlayerCaptions2';
import { FeatureFlagKeys } from '@/services/featureFlag';
import featureFlagStore from '@/stores/featureFlagStore';
import { classnames } from '@/libs/utils';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import { SizeConfig } from '@/libs/sharedAPI/sizeConfig/SizeConfig';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';

function CanvasAudiogramCaptions({
  videoSizeConfig,
  captionContainerSizes,
  captionFontSize
}: {
  videoSizeConfig: SizeConfig;
  captionContainerSizes: { width: number; height: number };
  captionFontSize: number;
}) {
  const { clipData } = useClipsContext();
  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);
  const isEasyClipCustomizerEnabled = featureFlags[FeatureFlagKeys.Use_CL_Easy_Clip_Customizer];
  const parentRef = useRef<HTMLDivElement>(null);

  const [areCaptionsSelected, setAreCaptionsSelected] = useState(false);

  const toggleAreCaptionsSelected = useCallback(() => {
    if (clipData.locked) return;
    if (!areCaptionsSelected) EventBus.dispatch(CustomEvents.OpenEditCaptions);
    setAreCaptionsSelected(val => !val);
  }, [clipData.locked]);

  useEffect(() => {
    if (!isEasyClipCustomizerEnabled) return;
    function handleOutsideClick(e: MouseEvent) {
      if (!areCaptionsSelected) return;
      if (!parentRef.current?.contains(e.target as Node)) {
        setAreCaptionsSelected(false);
      }
    }
    document.addEventListener('pointerdown', handleOutsideClick);
    return () => {
      document.removeEventListener('pointerdown', handleOutsideClick);
    };
  }, [isEasyClipCustomizerEnabled, areCaptionsSelected]);

  const captionContainerStyle = useMemo(() => {
    return videoSizeConfig.getCaptionAttributes({
      containerHeight: captionContainerSizes.height,
      containerWidth: captionContainerSizes.width,
      captionFontSize
    });
  }, [captionContainerSizes.height, captionContainerSizes.width, captionFontSize, videoSizeConfig]);

  return (
    <div
      className="absolute"
      ref={parentRef}
      style={{
        ...captionContainerStyle,
        width: `calc(100% - ${captionContainerStyle.right})`,
        fontSize: `${parseInt(captionContainerStyle.fontSize) * (clipData.asset_metadata.caption_styles?.scale || 1)}px`
      }}
    >
      {isEasyClipCustomizerEnabled && areCaptionsSelected && (
        <div
          className="absolute left-[-10px] top-[-10px] flex h-[calc(100%+16px)] w-[104%] cursor-pointer justify-center border-2 -outline-offset-8"
          style={{
            borderColor: clipData.asset_metadata.magicLayout?.textColor || '#FFFFFF'
          }}
        >
          <CaptionsMenu isVisible={true} parentRef={parentRef.current} />
        </div>
      )}
      <div
        className={classnames('h-full w-full', { 'cursor-pointer': isEasyClipCustomizerEnabled && !clipData.locked })}
        onClick={toggleAreCaptionsSelected}
      >
        <div className="flex flex-wrap gap-[0.15em] whitespace-normal">
          <CanvasPlayerCaptions2 />
        </div>
      </div>
    </div>
  );
}

export default memo(CanvasAudiogramCaptions);
