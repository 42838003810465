import { memo, useEffect, useState, useSyncExternalStore } from 'react';
import SavedSearchSettings from './SavedSearch/SavedSearchSettings';
import SessionSettings from './Session/SessionSettings';
import { savedAISearch } from '@/stores/savedAISearch';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';

function ContentSettings() {
  const savedAISearchStore = useSyncExternalStore(savedAISearch.subscribe, savedAISearch.getSnapshot);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSpeakerId, setSelectedSpeakerId] = useState<string | null>(null);

  function openSettings({ speakerId }: { speakerId: string | null }) {
    setIsOpen(true);
    setSelectedSpeakerId(speakerId);
  }

  function closeSettings() {
    setIsOpen(false);
  }

  useEffect(() => {
    const eventListener = EventBus.on(CustomEvents.OpenContentSettings, openSettings);
    return () => {
      EventBus.off(CustomEvents.OpenContentSettings, eventListener);
    };
  }, []);

  return !isOpen ? (
    <></>
  ) : !!savedAISearchStore?.id ? (
    <SavedSearchSettings onClose={closeSettings} selectedSpeakerId={selectedSpeakerId} />
  ) : (
    <SessionSettings onClose={closeSettings} selectedSpeakerId={selectedSpeakerId} />
  );
}

export default memo(ContentSettings);
